import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import LinkList from '../../../components/LinkList/LinkList';
import Alert from '../../../components/Alert/Alert';

const TechnicalHubPage = () => {
	const intl = useIntl();

	return (
		<Layout
			header={<span className='bold'>Technical Research & Education: Resource Hub</span>}
			breadcrumb={{
				text: 'Research & Education: Resource Hub',
				path: '/about/research-and-education-program/research-education-resource-hub/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.homeDescription'
				})}
				lang={intl.locale}
				title='Technical Research & Education: Resource Hub'
			/>
			<Container>
				{intl.locale === 'fr' && (
					<Row align='stretch'>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<p>
							In partnership with stakeholders, the HCRA will research and develop educational educational
							resources that prepare builders for upcoming technical trends and best practices in home
							construction.
						</p>
						<p>
							<i>
								This site will continue to be updated as new training offerings and communications
								become available.
							</i>
						</p>
						<LinkList
							links={[
								{
									path: `/about/research-and-education-program/technical-research-and-education-resource-hub/2024-ontario-building-code-updates/`,
									text: '2024 Ontario Building Code Updates',
									internal: true
								},
								{
									path: `/about/research-and-education-program/technical-research-and-education-resource-hub/upcoming-educational-offerings/`,
									text: 'Upcoming Educational Offerings',
									internal: true
								}
							]}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default TechnicalHubPage;
